import { type UserError } from "@ts/shopify-storefront";
import { useCallback, useState } from "react";
import { useLocation } from "./useLocation";
import { WishlistSharePOSTAPIRequest } from "../api/customers/me/wishlist/share";

type FunctionResponse<T = unknown> = {
  status?: "success" | "error";
  body?: string | T;
};

export const useFunctionsGatsby = () => {
  const { shopifyStore } = useLocation();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<UserError[] | string[]>([]);

  const request = useCallback(
    async (endpoint, data, overrides: RequestInit = {}) => {
      setLoading(true);
      setErrors([]);

      return fetch(`/api/${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        ...overrides,
        ...(overrides.method !== "GET" && { body: JSON.stringify(data) }),
      })
        .then((res) => res.json())
        .then((result) => {
          setLoading(false);
          return result;
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          setErrors([error]);
          return error;
        });
    },
    [setLoading, setErrors, fetch],
  );

  const backInStock = useCallback<
    <T>(email: string, variant: string) => Promise<FunctionResponse<T>>
  >(
    async (email, variant) => {
      const { status, body } = await request("backinstock", { email, variant });
      return { status, body };
    },
    [request],
  );

  const shareWishlistByEmail = useCallback<
    <T>(
      email: string,
      body: WishlistSharePOSTAPIRequest,
    ) => Promise<FunctionResponse<T>>
  >(
    async (email, body) => {
      const endpoint = `customers/me/wishlist/share?shop=${shopifyStore}&email=${email}`;
      const { status } = await request(endpoint, body);
      return { status };
    },
    [request],
  );

  const submitReturnCase = useCallback<
    <T, K>(data: T) => Promise<FunctionResponse<K>>
  >(
    async (data) => {
      const endpoint = `cases/return`;
      const { status, body } = await request(endpoint, data);
      return { status, body };
    },
    [request],
  );

  const productReviews = useCallback<
    <T>(
      productGroup: string,
      page: number,
      perPage: number,
      ratingFilter?: number,
      sortOrder?: string,
      searchKeyword?: string,
    ) => Promise<FunctionResponse<T>>
  >(
    async (
      productGroup,
      page,
      perPage,
      ratingFilter = 0,
      sortOrder = "highest_rating",
      searchKeyword,
    ) => {
      const endpoint = `reviews?lookup=${productGroup}&page=${page}&per_page=${perPage}&rating=${ratingFilter}&sort=${sortOrder}&keyword=${encodeURIComponent(searchKeyword)}`;
      const method = "GET";
      const { status, body } = await request(endpoint, {}, { method });

      return { status, body };
    },
    [request],
  );

  const productReviewAdd = useCallback<
    <T>(
      email: string,
      name: string,
      review: string,
      title: string,
      rating: number,
      sku: string,
    ) => Promise<FunctionResponse<T>>
  >(
    async (email, name, review, title, rating, sku) => {
      const endpoint = `reviews?store=bared.`;
      const { status, body } = await request(endpoint, {
        email,
        name,
        review,
        title,
        rating,
        sku,
      });
      return { status, body };
    },
    [request],
  );

  const productQuestions = useCallback<
    <T>(
      productGroup: string,
      page: number,
      perPage: number,
      sortOrder?: string,
    ) => Promise<FunctionResponse<T>>
  >(
    async (productGroup, page, perPage, sortOrder = "date_asc") => {
      const endpoint = `questions?lookup=${productGroup}&page=${page}&per_page=${perPage}&sort=${sortOrder}`;
      const method = "GET";
      const { status, body } = await request(endpoint, {}, { method });

      return { status, body };
    },
    [request],
  );

  const productQuestionAdd = useCallback<
    <T>(
      email: string,
      grouping_hash: string,
      name: string,
      question: string,
      url: string,
    ) => Promise<FunctionResponse<T>>
  >(
    async (email, grouping_hash, name, question, url) => {
      const endpoint = "questions?store=bared.";
      const { status, body } = await request(endpoint, {
        email,
        grouping_hash,
        name,
        question,
        url,
      });

      return { status, body };
    },
    [request],
  );

  return {
    backInStock,
    shareWishlistByEmail,
    submitReturnCase,
    productReviews,
    productReviewAdd,
    productQuestions,
    productQuestionAdd,
    errors,
    loading,
    setErrors,
  };
};
